import React, { useEffect } from "react"
import { AiFillCode } from "@react-icons/all-files/ai/AiFillCode"
import { AiFillApi } from "@react-icons/all-files/ai/AiFillApi"
import { AiFillCodeSandboxCircle } from "@react-icons/all-files/ai/AiFillCodeSandboxCircle"
import { RiDatabaseFill } from "@react-icons/all-files/ri/RiDatabaseFill"
import { RiDatabase2Fill } from "@react-icons/all-files/ri/RiDatabase2Fill"
import { FaTools } from "@react-icons/all-files/fa/FaTools"
import Card from "react-bootstrap/Card"
import Seo from "../components/seo"
import { useInView } from "react-intersection-observer"

const Services = ({ setTransparentNav, transparentNav }) => {
  const [ref, inView] = useInView({
    threshold: 0.75,
    initialInView: true,
  })
  useEffect(() => {
    if (inView) {
      setTransparentNav(true)
    } else {
      setTransparentNav(false)
    }
  }, [inView])
  return (
    <>
      <Seo
        title="Services"
        description="The services and service models we offer."
      />
      <div className={`services`}>
        <div className={`page-heading`} ref={ref}>
          <div className={`overlay`}>
            <h1>Services</h1>
          </div>
        </div>
        <div className={`page-content`}>
          <div className={`text-box`}>
            <h2>What services do we offer?</h2>
            <p>
              Our service model is{" "}
              <strong>flexible to suit your requirements</strong>. We can
              provide you with the following services from a single day
              engagement, to multiple days engagement per week, up to full-time
              engagements for short projects:
            </p>
          </div>
          <div className={`container-fluid p-3 pt-4`}>
            <div className={`row`}>
              <div className={`col-lg-4 col-md-6 mb-3`}>
                <Card className={`h-100`}>
                  <Card.Body>
                    <Card.Title className={`icon-title`}>
                      <div className={`icon`}>
                        <AiFillApi />
                      </div>
                      <div>API Development</div>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        <ul>
                          <li>Rest and GraphQL development.</li>
                          <li>
                            Rapid development using frameworks such as Apollo,
                            Prisma, Hasura, Feathers, Django.
                          </li>
                        </ul>
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className={`col-lg-4 col-md-6 mb-3`}>
                <Card className={`h-100`}>
                  <Card.Body>
                    <Card.Title className={`icon-title`}>
                      <div className={`icon`}>
                        <RiDatabase2Fill />
                      </div>
                      <div>Database Development</div>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        <ul>
                          <li>
                            Datawarehouse and Datalake design and development.
                          </li>
                          <li>Data Migration.</li>
                          <li>Integration services.</li>
                        </ul>
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className={`col-lg-4 col-md-6 mb-3`}>
                <Card className={`h-100`}>
                  <Card.Body>
                    <Card.Title className={`icon-title`}>
                      <div className={`icon`}>
                        <AiFillCodeSandboxCircle />
                      </div>
                      <div>Infrastructure as Code</div>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        <ul>
                          <li>
                            Code implementation of cloud infrastructure on Azure
                            and AWS.
                          </li>
                          <li>Microservices deployment on Kubernetes.</li>
                        </ul>
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className={`col-lg-4 col-md-6 mb-3`}>
                <Card className={`h-100`}>
                  <Card.Body>
                    <Card.Title className={`icon-title`}>
                      <div className={`icon`}>
                        <FaTools />
                      </div>
                      <div>Development Tooling and Techniques</div>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        <ul>
                          <li>
                            Advice, design and development of CI/CD pipelines on
                            Azure DevOps, AWS CloudDeploy or a blend of tools
                            and technologies to leverage existing investments
                            and skill sets.
                          </li>
                          <li>Test Driven Development.</li>
                          <li>Scrum Management.</li>
                        </ul>
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className={`col-lg-4 col-md-6 mb-3`}>
                <Card className={`h-100`}>
                  <Card.Body>
                    <Card.Title className={`icon-title`}>
                      <div className={`icon`}>
                        <RiDatabaseFill />
                      </div>
                      <div>Data Governance</div>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        Development and review of:
                        <ul>
                          <li>Solution Architecture and Implementation</li>
                          <li>
                            Compliance with Privacy Act (Aust) and GDPR (EU).
                            Privacy Impact Assessment.
                          </li>
                          <li>Cyber Security Review.</li>
                          <li>ISO 27001 Compliance.</li>
                        </ul>
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className={`col-lg-4 col-md-6 mb-3`}>
                <Card className={`h-100`}>
                  <Card.Body>
                    <Card.Title className={`icon-title`}>
                      <div className={`icon`}>
                        <AiFillCode />
                      </div>
                      <div>Code Development</div>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        <ul>
                          <li>
                            Assistance with management of existing code base.
                            Languages such as javascript, typescript, python,
                            java, rust etc.
                          </li>
                          <li>Greenfield project work.</li>
                          <li>Web &amp; Desktop Application Development.</li>
                          <li>UI's to integrate with exisitng systems.</li>
                        </ul>
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
          <div className={`text-box mt-4`}>
            <h2>Interested or have questions?</h2>
            <p>
              Contact us to discuss your requirements:{" "}
              <a href={`tel:+61415811163`}>+61 415 811 163</a>
              <br />
              <small>(Minimum engagement is one day.)</small>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Services
